export const uploadFailedMailto = `mailto:help@celebrate.buzz?subject=${encodeURIComponent(
  'Failed to upload'
)}`;

export const minVideoVolume = 0;
export const maxVideoVolumeSliderValue = 3;
export const maxVideoVolumeServerValue = 10;

export const UploadInProgressTrigger = 180000;
export const viewChangePixels = 1024;

export const MarketingSite = 'https://www.celebrate.buzz/';

export const celebrateDomainAddress = 'hello@celebrate.buzz';

export const CELEBRATE_SMTP_SECURE_TOKEN = '86fd83f7-ef44-4ad4-b282-becd86353d1d';

export const supportedImageTypes = [
  '.png',
  '.jpeg',
  '.jpg',
  '.heic',
  '.tif',
  '.tiff',
  '.jfif',
  '.gif',
];

export const supportedVideoTypes = [
  '.mov',
  '.mp4',
  '.m4v',
  '.3gp',
  '.mkv',
  '.webm',
  '.mpg',
  '.mpeg',
];

export const supportedAudioTypes = ['.wav', '.amr', '.mp3', '.m4a', '.aac'];

export const unknownTemplateId = '805c8e21-a460-4726-851b-431403782822';

export const singleVideoTemplateId = '3055a1ee-1530-4d72-8929-363fcc433524';

export const videoGuestBookTemplateId = 'a8d86eaa-f23c-4d6f-aa48-faacac030c03'; // change the value for production 'a8d86eaa-f23c-4d6f-aa48-faacac030c03'

export const qandaTemplateId = '52f523ab-a9fb-45f2-9553-ffeec073f934';

export const celebrationSettingId = '17d1fead-f1cf-4083-adeb-2119a94b114c';

export const DEFAULT_CURRENY = 'usd';

export const RightTilt = -90;

export const LeftTilt = 90;

export const VIDEO_GUEST_BOOK = 'video_guest_book';

export const CLASSIC_THEME = 'normal';

export const QANDA_THEME = 'the_newlywed_game';

export const formInitialValues = {
  'for-whom': '',
  'celebration-occasion-id': '',
  'occasion-date': '',
  'custom-occasion': '',
  'due-date': '',
  'celebration-template-id': null,
};

export const CELEBRATE_REVIEW_PAGE = 'https://www.celebrate.buzz/review';

export const CELEBRATE_HELP_PAGE = 'https://help.celebrate.buzz/';

export const CELEBRATE_GREAT_MESSAGES_PAGE =
  'https://help.celebrate.buzz/category/78-how-to-get-great-messages';

export const CELEBRATE_TIPS_PAGE =
  'https://help.celebrate.buzz/article/97-tips-to-get-more-and-better-responses';

export const CELEBRATE_BRIDAL_SHOWER = 'https://www.celebrate.buzz/bridal-shower-bachelorette';

export const CELEBRATE_CLASSIC_HELP_PAGE =
  'https://help.celebrate.buzz/article/102-how-does-a-classic-video-work';

export const CELEBRATE_CLASSIC_MARKETING_PAGE =
  'https://www.celebrate.buzz/create-surprise-video-gift';

export const CELEBRATE_CLASSIC_LEARN_MORE_PAGE = 'https://celebrate.buzz/learn-more/classic';

export const CELEBRATE_QA_HELP_PAGE =
  'https://help.celebrate.buzz/article/101-how-does-a-q-a-video-work';

export const CELEBRATE_QA_MARKETING_PAGE = 'https://www.celebrate.buzz/question-answer-video-maker';

export const CELEBRATE_QA_LEARN_MORE_PAGE = 'https://celebrate.buzz/learn-more/qanda';

export const CELEBRATE_VGB_HELP_PAGE =
  'https://help.celebrate.buzz/article/100-how-does-a-video-guestbook-work';

export const CELEBRATE_VGB_MARKETING_PAGE = 'https://www.celebrate.buzz/video-guestbook';

export const CELEBRATE_VGB_LEARN_MORE_PAGE = 'https://celebrate.buzz/learn-more/vgb';

export const PAYWALL_HELP = 'https://www.celebrate.buzz/learn-more/whats-included';

export const SEE_EXAMPLE_LINK = 'https://player.vimeo.com/video/823512356';

export const COFFE_VIDEO_BOOK_HELP = 'https://www.celebrate.buzz/coffee-table-book';
