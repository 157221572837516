import React, { useState, createContext, useEffect } from 'react';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import store from 'store';
import { useClearEntityCache } from '@/lib/entities';
import { useVisibilityChange } from '@/hooks/user';

const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

const ReferralsContext = createContext();
function generateRandomString(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = charset.length;

  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * charactersLength);
    result += charset.charAt(randomIndex);
  }

  return result;
}

function Referrals(props) {
  const [value, setValue] = useState(null);

  const { data, isValidating, error } = useSWR(`/v3/me`);
  const { query, replace, push } = useRouter();
  const clearCache = useClearEntityCache();

  useVisibilityChange((timeAway) => {
    const fifteenMinutes = 15 * 60 * 1000;

    if (timeAway > fifteenMinutes) {
      window.location.reload();
    }
  });

  useEffect(() => {
    if (window.location.search.includes('irclickid=')) store.set('iufi', true);
  }, []);

  useEffect(() => {
    if (
      !window.location.href.includes('/invitations') &&
      !window.location.href.includes('/event') &&
      store.get('token') &&
      error?.response?.status === 401
    ) {
      store.remove('token');
      store.remove('selectedDeviceID');
      store.remove('selectedAudioDeviceID');
      clearCache();
      push('/sign-in');
    }
  }, [error?.response?.status]);

  useEffect(() => {
    if (!isValidating && store.get('iufi') && window.ire) {
      if (data?.status === 200 && data?.data?.data?.attributes) {
        const { id, 'email-sha1': emailSHA1 } = data.data.data.attributes;

        window.ire('identify', { customerId: id, customerEmail: emailSHA1 });
        if (query?.['account-created']) {
          window.ire(
            'trackConversion',
            42434,
            {
              orderId: generateRandomString(64),
              customerId: id,
              customerEmail: emailSHA1,
            },
            {
              verifySiteDefinitionMatch: true,
            }
          );
          replace('/');
        }
      } else {
        window.ire('identify', { customerId: '', customerEmail: '' });
      }
    }
  }, [isValidating]);

  useEffect(() => {
    if (typeof document !== 'undefined' && !value) {
      // const referrer = {};
      const params = new URLSearchParams(document.referrer);
      const nextValue = {};
      utmParams.forEach((utmParam) => {
        if (params.has(utmParam)) {
          nextValue[utmParam] = params.get(utmParam);
        }
      });
      setValue(nextValue);
    }
  }, [value, setValue]);

  return <ReferralsContext.Provider value={value} {...props} />;
}

export default Referrals;
